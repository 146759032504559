import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  catchError,
  first,
  map,
  Observable,
  shareReplay,
  throwError,
} from 'rxjs';

import { NavigationService } from '../../navigation/service/navigation.service';
import { ApplicationSettingsApi } from '../api/application-settings.api';
import { ApplicationSettingsNotFoundError } from '../api/error/application-settings-not-found-error';
import { ApplicationSettings } from '../api/model/application-settings';

@Injectable()
export class ApplicationSettingsService {
  public applicationSettings$: Observable<ApplicationSettings> =
    this.applicationSettingsApi.getApplicationSettings().pipe(
      first(),
      catchError((err: unknown) => {
        if (
          (err instanceof HttpErrorResponse && err.status === 404) ||
          err instanceof ApplicationSettingsNotFoundError
        ) {
          this.navigationService.navigateTo404Page();
        }

        return throwError(() => err);
      }),
      shareReplay({ bufferSize: 1, refCount: false }),
    );

  constructor(
    private readonly applicationSettingsApi: ApplicationSettingsApi,
    private readonly navigationService: NavigationService,
  ) {}

  logoDataUrl() {
    return this.applicationSettings$.pipe(
      map((applicationSettings) => applicationSettings.clientPortalLogo),
    );
  }

  brandName() {
    return this.applicationSettings$.pipe(
      map((applicationSettings) => applicationSettings.brandName),
    );
  }
}

import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import * as i18nIsoLanguages from '@cospired/i18n-iso-languages';
import languageNamesDe from '@cospired/i18n-iso-languages/langs/de.json';
import languageNamesEn from '@cospired/i18n-iso-languages/langs/en.json';
import languageNamesFr from '@cospired/i18n-iso-languages/langs/fr.json';
import languageNamesNl from '@cospired/i18n-iso-languages/langs/nl.json';
import * as i18nIsoCountries from 'i18n-iso-countries';
import countryNamesDe from 'i18n-iso-countries/langs/de.json';
import countryNamesEn from 'i18n-iso-countries/langs/en.json';
import countryNamesFr from 'i18n-iso-countries/langs/fr.json';
import countryNamesNl from 'i18n-iso-countries/langs/nl.json';

import { Language } from '../model/language';
import { LocaleToLanguagePipe } from '../pipe/locale-to-language.pipe';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  public readonly AVAILABLE_TRANSLATIONS: string[] = [
    'en-US',
    'fr-FR',
    'fr-BE',
    'nl-BE',
    'de-DE',
  ];

  constructor(
    @Inject(LOCALE_ID) private readonly locale: string,
    private readonly localeToLanguagePipe: LocaleToLanguagePipe,
  ) {
    i18nIsoCountries.registerLocale(countryNamesEn);
    i18nIsoCountries.registerLocale(countryNamesFr);
    i18nIsoCountries.registerLocale(countryNamesNl);
    i18nIsoCountries.registerLocale(countryNamesDe);

    i18nIsoLanguages.registerLocale(languageNamesEn);
    i18nIsoLanguages.registerLocale(languageNamesFr);
    i18nIsoLanguages.registerLocale(languageNamesNl);
    i18nIsoLanguages.registerLocale(languageNamesDe);
  }

  getLanguage(): Language {
    return this.localeToLanguagePipe.transform(this.locale);
  }

  getLocale(): string {
    return this.locale;
  }

  getNameInCurrentLanguage(localizedNames: Record<Language, string>) {
    const currentLanguage = this.getLanguage();

    return localizedNames[currentLanguage];
  }

  getLocalizedCountryName(countryCode: string): string {
    return <string>i18nIsoCountries.getName(countryCode, this.getLanguage());
  }

  getLocalizedLanguageName(language: string): string {
    return <string>i18nIsoLanguages.getName(language, this.getLanguage());
  }

  getIsoCountryCode(countryName: string): string {
    return <string>(
      i18nIsoCountries.getAlpha2Code(countryName, this.getLanguage())
    );
  }
}

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';

import { Sha256Service } from '../../analytics/sha-256.service';
import { ApplicationSettingsService } from '../../application-settings/service/application-settings.service';
import { Language } from '../../language/model/language';
import { LanguageService } from '../../language/service/language.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit {
  language: Language = 'en';
  flagCountryCode: string = 'gb';
  logoDataUrl$: Observable<string>;
  useremail$ = this.sha256Service.email$;
  userphone$ = this.sha256Service.phone$;

  constructor(
    protected readonly modalService: NgbModal,
    private readonly languageService: LanguageService,
    private readonly applicationSettingsService: ApplicationSettingsService,
    private readonly sha256Service: Sha256Service,
  ) {
    this.logoDataUrl$ = this.applicationSettingsService.logoDataUrl();
  }

  ngOnInit(): void {
    this.language = this.languageService.getLanguage();
    this.flagCountryCode = this.getCountryCodeFromLocale();
  }

  private getCountryCodeFromLocale() {
    const US_FLAG_COUNTRY_CODE: string = 'us';
    const GB_FLAG_COUNTRY_CODE: string = 'gb';

    return this.languageService
      .getLocale()
      .toLowerCase()
      .substring(3, 5)
      .replace(US_FLAG_COUNTRY_CODE, GB_FLAG_COUNTRY_CODE);
  }
}
